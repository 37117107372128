import React, { useState, useEffect } from "react";

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../actions/auth/auth';
import {  } from '../../actions/home/home';
import Logo from "../../assets/images/logo-sm.png";

const Header = () => {

    const dispatch = useDispatch();

    const token = useSelector(state => state.AuthReducer.token);
    const homedata = useSelector(state => state.HomeReducer.homedata);

    useEffect(() => {
      //  dispatch(getHomeDataService(token))
    }, []);

    return(
        <div className="navbar-custom">
        <ul className="list-unstyled topnav-menu float-right mb-0">
            <li className="d-none d-sm-block">
                <form className="app-search">
                    <div className="app-search-box">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Ara..." />
                            <div className="input-group-append">
                                <button className="btn" type="submit">
                                    <i className="fe-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </li>
            <li className="dropdown notification-list">
                <a className="nav-link dropdown-toggle  waves-effect" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                    <i className="fe-bell noti-icon"></i>
                    <span className="badge badge-danger rounded-circle noti-icon-badge">0</span>
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-lg">
                    <div className="dropdown-item noti-title">
                        <h5 className="m-0">
                            <span className="float-right">
                                <a href="" className="text-dark">
                                    <small>Hepsi sil</small>
                                </a>
                            </span>Bildirimler
                        </h5>
                    </div>

                    <a href="javascript:void(0);" className="dropdown-item text-center text-primary notify-item notify-all">
                        Voir tout
                        <i className="fi-arrow-right"></i>
                    </a>

                </div>
            </li>

            <li className="dropdown notification-list">
                <a className="nav-link dropdown-toggle nav-user mr-0 waves-effect" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                     <img src={Logo} alt="user-image" className="rounded-circle" /> 
                    <span className="pro-user-name ml-1">
                   Admin <i className="mdi mdi-chevron-down"></i>
                    </span>
                </a>
                <div className="dropdown-menu dropdown-menu-right profile-dropdown ">

                    <div className="dropdown-header noti-title">
                        <h6 className="text-overflow m-0">Hoşgeldiniz !</h6>
                    </div>


                    <a href="javascript:void(0);" className="dropdown-item notify-item">
                        <i className="fe-user"></i>
                        <span>Hesabim</span>
                    </a>


                    <a href="javascript:void(0);" className="dropdown-item notify-item">
                        <i className="fe-settings"></i>
                        <span>Ayarlar</span>
                    </a>


                    <a href="javascript:void(0);" className="dropdown-item notify-item">
                        <i className="fe-lock"></i>
                        <span>Ekran kilit</span>
                    </a>

                    <div className="dropdown-divider"></div>


                    <a onClick={() => dispatch(logOut())} style={{cursor:"pointer"}} className="dropdown-item notify-item">
                        <i className="fe-log-out"></i>
                        <span>Çikiş</span>
                    </a>

                </div>
            </li>
        </ul>
        <div className="logo-box">
            <a href="index.html" className="logo text-center">
                <span className="logo-lg">
                    <img src="assets/images/logo-dark.png" alt="" height="16" />
                    <span className="logo-lg-text-light">Xeria</span>
                </span>
                <span className="logo-sm">
                    <span className="logo-sm-text-dark">X</span>
                    <img src="assets/images/logo-sm.png" alt="" height="24" />
                </span>
            </a>
        </div>
        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
            <li>
                <button className="button-menu-mobile disable-btn waves-effect">
                    <i className="fe-menu"></i>
                </button>
            </li>
            <li>
                <h4 className="page-title-main">Panel</h4>
            </li>
        </ul>
    </div>
    )
}
export default Header;