import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import Checkbox from "react-custom-checkbox";
import { useDispatch, useSelector } from "react-redux";
import { getSemtService, uploadUrl1FileService, uploadUrl2FileService, uploadUrl3FileService, uploadUrl4FileService, addIlanService } from "../../actions/home/home";
import { Redirect } from "react-router-dom";
import RichTextEditor from 'react-rte';
import Select from 'react-select';

import Navbar from "../../components/navbar/navbar";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Loader from "../../components/loader/loader";

const gorusmeyerioptions = [
    { value: 'ev', label: 'ev' },
    { value: 'otel', label: 'otel' },
    { value: 'rezidans', label: 'rezidans' },
];



const CreerOffre = () => {

    const Dispatch = useDispatch();

    const token = useSelector(state => state.AuthReducer.token);
    const semt = useSelector(state => state.HomeReducer.semt);
    const loadingsemt = useSelector(state => state.HomeReducer.loadingsemt);
    const loadingaddilan = useSelector(state => state.HomeReducer.loadingaddilan);
    const addilanstatus = useSelector(state => state.HomeReducer.addilanstatus);
    const url1 = useSelector(state => state.HomeReducer.url1);
    const url2 = useSelector(state => state.HomeReducer.url2);
    const url3 = useSelector(state => state.HomeReducer.url3);
    const url4 = useSelector(state => state.HomeReducer.url4);

    useEffect(() => {
        Dispatch(getSemtService(token))
    }, []);

    const [error, setError] = useState(false);
    const [text, setText] = useState(RichTextEditor.createEmptyValue());
    const [selectedOption, setSelectedOption] = useState(null);
    const [ilanBasligi, setIlanBasligi] = useState("");
    const [isim, setIsim] = useState("");
    const [sira, setSira] = useState(null);
    const [telefon, setTelefon] = useState("");
    const [whatssap, setWhatssap] = useState("");
    const [telegram, setTelegram] = useState("");
    const [yas, setYas] = useState(null);
    const [gorusme, setGorusme] = useState(null);
    const [zaman, setZaman] = useState(null);
    const [bilgi, setBilgi] = useState(null);
    const [selectedFileUrl1, setSelectedFileUrl1] = useState(null);
    const [selectedFileUrl2, setSelectedFileUrl2] = useState(null);
    const [selectedFileUrl3, setSelectedFileUrl3] = useState(null);
    const [selectedFileUrl4, setSelectedFileUrl4] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        let gorus = ""

        gorusme.map((item) => {
            gorus = gorus + item.value + ' - '
        })

        if (ilanBasligi !== "" && ilanBasligi.trim() !== "" && text !== "" && selectedOption !== null && isim !== "" && isim.trim() !== "" && sira !== "" && sira.trim() !== ""
            && telefon !== "" && telefon.trim() !== "" && whatssap !== "" && whatssap.trim() !== "" && telegram !== "" && telegram.trim() !== "" && yas !== null && yas.trim() !== "" && gorusme !== ""
            !== "" && zaman !== "" && zaman.trim() !== "" && bilgi !== "" && bilgi.trim() !== "" && url1 !== "" && url1.trim() !== "" && url2 !== "" && url2.trim() !== "" && url3 !== "" && url3.trim() !== "" &&
            url4 !== "" && url4.trim() !== "") {

            Dispatch(addIlanService(selectedOption.value, ilanBasligi, isim, telefon, whatssap, gorus, telegram, Number(yas), bilgi, Number(zaman), text.toString('html'), url1, url2, url3, url4, token))
        }
        else {
            setError(true)
        }
    };

    let SemtList = semt.map((item) => {

        return (
            { value: item.id, label: item.name }
        )

    })

    const handleSemt = (e) => {
        setSelectedOption(e)
        console.log(`Option selected:`, e)
    }


    const handleText = (value) => {
        setText(value)
        value.toString('html')
    }


    const handleIlanBasligi = (e) => {
        setIlanBasligi(e.target.value)
        setError(false)
    }

    const handleIsim = (e) => {
        setIsim(e.target.value)
        setError(false)
    }

    const handleSira = (e) => {
        setSira(e.target.value)
        setError(false)
    }

    const handleTelefon = (e) => {
        setTelefon(e.target.value)
        setError(false)
    }

    const handleWhatssap = (e) => {
        setWhatssap(e.target.value)
        setError(false)
    }

    const handleTelegram = (e) => {
        setTelegram(e.target.value)
        setError(false)
    }

    const handleYas = (e) => {
        setYas(e.target.value)
        setError(false)
    }

    const handleGorumeYer = (e) => {
        setGorusme(e)
        setError(false)
    }

    const handleBilgi = (e) => {
        setBilgi(e.target.value)
        setError(false)
    }

    const handleFileUrl1Upload = (event) => {
        setSelectedFileUrl1(event.target.files[0]);
        Dispatch(uploadUrl1FileService(event.target.files[0]))
    };

    const handleFileUrl2Upload = (event) => {
        setSelectedFileUrl2(event.target.files[0]);
        Dispatch(uploadUrl2FileService(event.target.files[0]))
    };


    const handleFileUrl3Upload = (event) => {
        setSelectedFileUrl3(event.target.files[0]);
        Dispatch(uploadUrl3FileService(event.target.files[0]))
    };

    const handleFileUrl4Upload = (event) => {
        setSelectedFileUrl4(event.target.files[0]);
        Dispatch(uploadUrl4FileService(event.target.files[0]))
    };


    const handleZaman = (e) => {
        setZaman(e.target.value)
    }


    let redirect = null;

    if (addilanstatus) {
        redirect = <Redirect to="/get-ilan" />;
    }

    return (
        <>
            <div id="wrapper">
                {redirect}
                <Header />
                <Navbar />
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card-box">
                                        <h4 class="m-t-0 header-title">Yeni İlan oluşturmak</h4>
                                        <p class="text-muted mb-3 font-13">

                                        </p>

                                        <form onSubmit={handleSubmit}>

                                            {
                                                loadingsemt ? "Loading" : <div class="form-group col-md-6">
                                                    <label for="" class="col-form-label">
                                                        Semt
                                                    </label>
                                                    <Select
                                                        value={selectedOption}
                                                        onChange={handleSemt}
                                                        options={SemtList}
                                                        class="form-control"
                                                    />

                                                </div>
                                            }

                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label for="inputEmail4" class="col-form-label">
                                                        İlan başliği{" "}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={ilanBasligi}
                                                        onChange={handleIlanBasligi}
                                                        class="form-control"
                                                        placeholder="İlan Başliği"
                                                    />
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label for="inputEmail4" class="col-form-label">
                                                        İsim
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={isim}
                                                        onChange={handleIsim}
                                                        class="form-control"
                                                        placeholder="İsim"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-row">
                                                <div class="form-group col-md-3">
                                                    <label for="inputAddress2" class="col-form-label">
                                                        Sira
                                                    </label>
                                                    <input
                                                        type="number"
                                                        value={sira}
                                                        onChange={handleSira}
                                                        class="form-control"
                                                        id=""
                                                        placeholder="Sira"
                                                    />
                                                </div>

                                                <div class="form-group col-md-3">
                                                    <label for="inputAddress2" class="col-form-label">
                                                        Telefon
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={telefon}
                                                        onChange={handleTelefon}
                                                        class="form-control"
                                                        id=""
                                                        placeholder="Telefon"
                                                    />
                                                </div>

                                                <div class="form-group col-md-3">
                                                    <label for="" class="col-form-label">
                                                        Whatssap
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={whatssap}
                                                        onChange={handleWhatssap}
                                                        class="form-control"
                                                        id="inputAddress2"
                                                        placeholder="Whatssap"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-row">
                                                <div class="form-group col-md-3">
                                                    <label for="inputAddress2" class="col-form-label">
                                                        Telegram
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={telegram}
                                                        onChange={handleTelegram}
                                                        class="form-control"
                                                        id=""
                                                        placeholder="Telegram"
                                                    />
                                                </div>

                                                <div class="form-group col-md-3">
                                                    <label for="inputAddress2" class="col-form-label">
                                                        Yaş
                                                    </label>
                                                    <input
                                                        type="number"
                                                        value={yas}
                                                        onChange={handleYas}
                                                        class="form-control"
                                                        id=""
                                                        placeholder="Yaş"
                                                    />
                                                </div>

                                                <div class="form-group col-md-3">
                                                    <label for="" class="col-form-label">
                                                        Gorüşme yeri
                                                    </label>
                                                    <Select
                                                        value={gorusme}
                                                        onChange={handleGorumeYer}
                                                        options={gorusmeyerioptions}
                                                        isMulti
                                                        class="form-control"
                                                    />
                                                </div>

                                            </div>

                                            <div class="form-row">
                                                <div class="form-group col-md-3">
                                                    <label for="inputAddress2" class="col-form-label">
                                                        Bilgi
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={bilgi}
                                                        onChange={handleBilgi}
                                                        class="form-control"
                                                        placeholder="Bilgi"
                                                    />
                                                </div>

                                                <div class="form-group col-md-3">
                                                    <label for="inputAddress2" class="col-form-label">
                                                        Url
                                                    </label>
                                                    {

                                                    }
                                                    <input
                                                        type="file"
                                                        onChange={handleFileUrl1Upload}
                                                        class="form-control"
                                                        id="file"
                                                        placeholder="Resim Ekle"
                                                    />
                                                </div>

                                                <div class="form-group col-md-3">
                                                    <label for="inputAddress2" class="col-form-label">
                                                        Url2
                                                    </label>
                                                    <input
                                                        type="file"
                                                        onChange={handleFileUrl2Upload}
                                                        class="form-control"
                                                        id=""
                                                        placeholder="Resim Ekle"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-row">

                                                <div class="form-group col-md-4">
                                                    <label for="inputAddress2" class="col-form-label">
                                                        Url3
                                                    </label>
                                                    <input
                                                        type="file"
                                                        onChange={handleFileUrl3Upload}
                                                        class="form-control"
                                                        id=""
                                                        placeholder="Resim Ekle"
                                                    />
                                                </div>

                                                <div class="form-group col-md-4">
                                                    <label for="inputAddress2" class="col-form-label">
                                                        Url4
                                                    </label>
                                                    <input
                                                        type="file"
                                                        onChange={handleFileUrl4Upload}
                                                        class="form-control"
                                                        id=""
                                                        placeholder="Resim Ekle"
                                                    />
                                                </div>

                                                <div class="form-group col-md-3">
                                                    <label for="inputAddress2" class="col-form-label">
                                                        Ilan suresi
                                                    </label>
                                                    <input
                                                        type="number"
                                                        value={zaman}
                                                        onChange={handleZaman}
                                                        class="form-control"
                                                        id=""
                                                        placeholder="İlan suresi"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-row">
                                                <div class="form-group col-md-9">
                                                    <label for="inputAddress2" class="col-form-label">
                                                        İlan detayları
                                                    </label>
                                                    <RichTextEditor
                                                        value={text}
                                                        onChange={handleText}
                                                    />
                                                </div>
                                            </div>

                                            {error ? (
                                                <p style={{ color: "red" }}>
                                                    Bilgileri kontrol edin
                                                </p>
                                            ) : null}
                                            {loadingaddilan ? (
                                                <Loader />
                                            ) :


                                                <button
                                                    type="submit"
                                                    class="btn btn-primary"
                                                    style={{ marginTop: 10 }}
                                                >
                                                    Ekle
                                                </button>
                                            }

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
};
export default CreerOffre;
