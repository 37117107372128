import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSemtService } from "../../actions/home/home";
import { Redirect } from "react-router-dom";

import Navbar from "../../components/navbar/navbar";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Loader from "../../components/loader/loader";




const AddSemt = () => {

  const Dispatch = useDispatch();

  const token = useSelector((state) => state.AuthReducer.token);
  const addsemtstatus = useSelector((state) => state.HomeReducer.addsemtstatus);
  const loadingsemt = useSelector((state) => state.HomeReducer.loadingsemt);

  const [name, setName] = useState("");

  const [error, setError] = useState(false);


  const handleName = (e) => {
    setName(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name !== "" && name.trim() !== "") {
      Dispatch(addSemtService(name, token))
    }
    else {
      setError(true)
    }
  };

  let redirect = null;

  if (addsemtstatus) {
    redirect = <Redirect to="/get-semt" />;
  }

  return (
    <>
      <div id="wrapper">
        {redirect}
        <Header />
        <Navbar />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div class="row">
                <div class="col-md-12">


                  <div class="card-box">
                    <h4 class="m-t-0 header-title">Yeni Semt Ekle</h4>
                    <form onSubmit={handleSubmit}>
                      <div class="form-row">
                        <div class="form-group col-md-3">
                          <label for="inputAddress2" class="col-form-label">
                            semt Adı
                          </label>
                          <input
                            type="text"
                            value={name}
                            onChange={handleName}
                            class="form-control"
                            id=""
                            placeholder="Semt Adı"
                          />
                        </div>
                      </div>

                      {error ? <p style={{ color: "red" }}> Bilgieri kontrol edin </p> : null}

                      {loadingsemt ? (
                        <Loader />
                      ) : (
                        <button
                          type="submit"
                          class="btn btn-primary"
                          style={{ marginTop: 10 }}
                        >
                          Ekle
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div >
    </>
  );
};
export default AddSemt;
