import React from "react";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        2024 &copy; Escort by <a href="https://www.angeboutchoue.com/">Dez</a>
                    </div>
                    <div className="col-md-6">
                        <div className="text-md-right footer-links d-none d-sm-block">
                            {/* <a href="javascript:void(0);">A propos</a>
                            <a href="javascript:void(0);">Aide</a>
                            <a href="javascript:void(0);">Contact</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
};
export default Footer;